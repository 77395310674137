import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/syeda/corvette/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Why are Corvettes so expensive right now`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1036px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/141247fcf0ea1a7f76c2d9de341dbcda/69128/corvette_c8_2021.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFZhFWkpH//xAAaEAACAgMAAAAAAAAAAAAAAAABAgMhERIx/9oACAEBAAEFAr2ZpMJLIqk03Qa//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAQQDAAAAAAAAAAAAAAAAAQACESEgYXH/2gAIAQEABj8CLwJ0VMt4Gqqw/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIaFBcf/aAAgBAQABPyG2Y39Wc8xCFRUdqIAHI+WNXfJ//9oADAMBAAIAAwAAABCwL//EABYRAAMAAAAAAAAAAAAAAAAAAAEQYf/aAAgBAwEBPxAxf//EABYRAQEBAAAAAAAAAAAAAAAAAAEAMf/aAAgBAgEBPxADUkL/xAAdEAEAAgICAwAAAAAAAAAAAAABESEAMUHBYXGx/9oACAEBAAE/EBgOaWDG3isnmSEvbhMIAxAK9njEsEBg1jOyQpFQgx8yGkCFGrvvP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2021 Corvette Stingray C8 3LT",
            "title": "2021 Corvette Stingray C8 3LT",
            "src": "/static/141247fcf0ea1a7f76c2d9de341dbcda/69128/corvette_c8_2021.jpg",
            "srcSet": ["/static/141247fcf0ea1a7f76c2d9de341dbcda/f93b5/corvette_c8_2021.jpg 300w", "/static/141247fcf0ea1a7f76c2d9de341dbcda/b4294/corvette_c8_2021.jpg 600w", "/static/141247fcf0ea1a7f76c2d9de341dbcda/69128/corvette_c8_2021.jpg 1036w"],
            "sizes": "(max-width: 1036px) 100vw, 1036px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The current high prices of the Chevy Corvette are in line with the high demand for the car. Prices range from $60,995 for the C8 to more than $110,000 for the Z06. In addition to this, prices on older models are significantly lower. While the prices of the new model will continue to rise, the older models are still worth considering. You can get one of the first generation of Corvettes for as little as $40,000.
The new C6 Corvette is priced between $30,500 and $46,000. These models have plenty of power for the money. The median #2 value for a C6 base model is $26,900, but the higher the power, the more the price goes up. A top-of-the-line LS7-powered Z06 will set you back more than $41,000. A top-of-the-line, 638-hp supercharged ZR1 will run you about $65,000.`}</p>
    <p>{`The C6 Corvette is one of the most expensive models on the market. Despite its high price, it offers an incredible amount of performance for the price. A median #2 value C6 costs $26,900, while the top-of-the-line, supercharged ZR1 costs $65,000. Regardless of your budget, you'll be happy with your choice of this classic. You will never regret buying a Corvette.`}</p>
    <p>{`The price increase is applied to the entire Corvette lineup. If you buy a 2021 Corvette, you'll be lucky! You'll pay just over $1,100 more than if you buy one today. Ultimately, you'll still be paying a high price for an awesome automobile. And since you will have to pay thousands more in the long run, this increase in price isn't going to discourage anyone.`}</p>
    <p>{`The Chevrolet Corvette C8 continues to be the most popular sports car in the U.S., with the highest MSRP per unit. And it's hard to argue with its performance when you consider the price tag. The latest 2021 Corvette Stingray C8 3LT adds leather trim to the upper instrument panel, door panels, and console cover. This car is incredibly unique and has a massive backlog of orders. It's also one of the most expensive used cars in the world. If you are considering buying a new Corvette, you may want to consider a used one for a fraction of that price.`}</p>
    <p>{`The biggest reason for the price increase is the shortage of inventory. As a result, the limited supply of the car has made it virtually impossible to keep up with demand. This is causing a significant price increase. This is a natural consequence of the lack of new cars. This is a normal shortage of the parts that are used in the production of the cars. And because of the limited supply, this has caused the cars to be overpriced and sold out.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      